<script lang='ts'>

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import ApiDocDoc from '@/components/docs/admin/dev/ApiDocDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import Overlay from '@/components/Overlay.svelte'

  document.title = 'API documentation'

  let pages: any
  let showForm = false
  let currentPage: any
  let sum = 0

  const load = async () => {
    const data = await fetchData('admin/apiDoc', {})
    if (data.error) {
      notifier.info(data.error)
    } else {
      pages = data.pages
      sum = 0
      Object.keys(pages).forEach(key => {
        sum += Object.keys(pages[key]).length
      })
    }
  }
  load()

  function openDoc (type: any, page: any) {
    currentPage = pages[type][page]
    showForm = true
  }

  const closeModal = () => {
    showForm = false
  }

</script>
<p>Optional params have "?" on the start.</p>

Variables:
<abbr title='3 letter language code'> ISO</abbr>,
<abbr title='a1,a2,b1,b2,c1,c2'>LEVEL</abbr>

<p>Number of api endpoints: {sum}</p>
{#if pages}
  <ul>
    {#each Object.keys(pages) as type}
      {@const subpages = Object.keys(pages[type])}
      <li>{type} {subpages.length}
        <ul>
          {#each subpages as page}
            <li
              class='clickable-row'
              role='presentation'
              on:keypress={() => {}}
              on:click={() => openDoc(type, page)}>{type} {page}</li>
          {/each}
        </ul>
      </li>
    {/each}
  </ul>
  <hr />
  <table class='table'>
    {#each Object.keys(pages) as type}
      <tr>
        <th colspan='2'>{type}</th>
      </tr>
      {#each Object.keys(pages[type]) as page}
        <tr>

          <td class='clickable-row' on:click={() => openDoc(type, page)} on:keypress={() => {}}>
            {type} / {page}
          </td>
          <td>
            {pages[type][page].description}
          </td>
        </tr>
      {/each}
    {/each}
  </table>
{/if}
{#if showForm}
  <Overlay hidden={!showForm} onClick={closeModal}>
    <div class='popup-wrapper' class:-hidden={!showForm}>
      {#if currentPage}
        <h2>{currentPage.description}</h2>
        <p><strong>Front:</strong> {currentPage.front}</p>
        {#if currentPage.request}
          <h3>Request:</h3>
          <pre>
{JSON.stringify(currentPage.request, null, 2)}
          </pre>
        {/if}
        {#if currentPage.response}
          <pre>
        {JSON.stringify(currentPage.response, null, 2)}
          </pre>
        {/if}
      {/if}
    </div>
  </Overlay>
{/if}
<Documentation dateCompleted='2023-08-26'>
  <ApiDocDoc />
</Documentation>
<style>
  .popup-wrapper {
    max-width: 100vw;
    max-height: 100vh;
    padding: 2rem;
    background: var(--white-1);

    &.-hidden {
      display: none;
    }
  }

  .clickable-row {
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
</style>
